import React from "react";
import { graphql } from "gatsby";
import { Article } from "../models/article.model";
import PageWrapper from "../components/PageWrapper";
import logo from "../matrix-logo.svg";
import bospar from "../images/bospar.png";
import { ROUTE_LINKS } from "../utils/routes";

const NewsArticle04 = (props: any) => {
  const article: Article = props.data.article.frontmatter;

  return (
    <PageWrapper path={ROUTE_LINKS.news}>
      <main className="flex flex-col max-w-6xl md:mx-auto px-2.5 py-12">
        <div className="flex items-center justify-center h-12 sm:h-20 md:h-36 mb-5">
          <img
            src={logo}
            className="object-cover object-center h-full"
            alt="Logo"
          />
        </div>
        <h2 className="font-bold text-xl mb-10">Date: August 12th, 2023</h2>
        <h2 className="font-bold text-xl mb-10">
          Subject: Enhancing Accessibility and Usability of HUD Programs - A
          Response from Matrix Rental Solutions
        </h2>
        <p className="mb-5">
          We are thrilled to learn about the U.S. Department of Housing and
          Urban Development's (HUD) initiative to enhance the accessibility and
          usability of its programs. As a transformative software platform
          committed to driving inclusivity and efficiency in the rental housing
          market, Matrix Rental Solutions fully supports this endeavor to
          improve housing solutions for all.
          <br />
          <br />
          We recognize the significant impact that administrative burdens can
          have, particularly on marginalized and underserved communities. As
          part of our commitment to advancing equity and fostering inclusive,
          strong communities, Matrix Rental Solutions is eager to contribute our
          insights to this important discussion.
        </p>
        <p className="mb-5">
          In response to HUD's Request for Information, we offer the following
          suggestions to streamline and improve the accessibility of HUD
          programs:
        </p>
        <p className="mb-5">
          <strong>Simplifying Application and Eligibility Forms:</strong>
          <br />
          Standardizing forms, as demonstrated by the success of the Form 1003
          Uniform Residential Loan Application and the Form 1004 Uniform
          Residential Appraisal Report in the mortgage industry, has streamlined
          and simplified processes. Matrix Rental Solutions advocates for
          adopting this approach in the rental housing market, introducing the
          Universal Residential Rental Application and the Universal Residential
          Inspection Form. These standardized forms will create a user-friendly
          and consistent experience, reducing confusion and promoting
          inclusivity and efficiency in the rental market, benefiting both
          prospective residents and home providers.
        </p>
        <p className="mb-5">
          <strong>
            Introducing the Universal Residential Rental Application:
          </strong>
          <br />
          Matrix Rental Solutions powers the Universal Residential Rental
          Application, a transformative solution for the rental housing market.
          With seamless connections to over 1 million employers via its payroll
          connections and real-time data retrieval from 16,000 financial
          institutions, the Universal Residential Rental Application streamlines
          the application process. Automated document processing and secure
          connections to consumer reporting agencies ensure reliable and
          efficient data collection, benefiting both prospective residents and
          home providers. Matrix uses a multi-factor scoring model that shows a
          prospective resident's ability to pay.
        </p>
        <p className="mb-5">
          Inspired by the success of the Form 1003 Uniform Residential Loan
          Application, the Matrix standardized format reduces confusion,
          promoting inclusivity and efficiency in the rental market. Leveraging
          artificial intelligence and machine learning, income verification,
          asset evaluation, and rental history analysis are expedited with
          accuracy, ensuring fairness for all renters. Matrix Rental Solutions
          leads the way in transforming the rental application process, making
          housing more accessible for everyone.
        </p>
        <p className="mb-5">
          <strong>Introducing a Universal Residential Inspection Form:</strong>
          <br />
          The Universal Residential Inspection Form, powered by automated
          intelligence, is a transformative solution that addresses one of the
          most significant pain points in the rental housing sector—the tedious
          and time-consuming inspection process. Currently, the inspection
          process takes around 12 Business Days, leading to delays and
          frustrations for both renters and home providers. The Universal
          Residential Inspection Form streamlines the inspection process through
          a standardized checklist, ensuring that residents have safe, clean,
          and reliable housing without alienating the home providers responsible
          for the dwelling. By integrating automated intelligence, scheduling
          inspections become faster and more accurate, reducing the time it
          takes to complete the certification process.
          <br />
          <br />
          This innovative approach benefits both renters and home providers,
          providing assurance of a fair and standardized inspection process for
          renters and reducing downtime for home providers. The Universal
          Residential Inspection Form fosters a more harmonious relationship
          between renters and home providers, working collaboratively towards
          ensuring high-quality, safe housing for all. In line with the mortgage
          industry's success with standardization, we propose the introduction
          of a common, recognized document for inspections, ensuring a
          consistent checklist that inspectors nationwide can follow. This
          standardized approach aims to expedite and harmonize the inspection
          process, promoting fairness and efficiency. Ultimately, the Universal
          Residential Inspection Form benefits both renters and home providers
          in the rental housing market, paving the way for a more streamlined
          and equitable rental experience.
        </p>
        <p className="mb-5">
          <strong>Reducing Burden for Vulnerable Groups:</strong>
          <br />
          To ensure that HUD programs cater to the needs of vulnerable groups,
          we recommend investing in multi-language support for application
          processes. By providing translation services, individuals with limited
          English proficiency can access and understand the required information
          more effectively.
        </p>
        <p className="mb-5">
          <strong>Responsibly Sharing Data and Information:</strong>
          <br />
          To foster efficiency and collaboration, we propose establishing secure
          data-sharing protocols among federal agencies and the public. However,
          privacy and data protection should remain a priority. Utilizing
          advanced technologies, such as blockchain, can offer a secure and
          transparent data-sharing framework.
        </p>
        <p className="mb-5">
          <strong>
            Harnessing Artificial Intelligence and Machine Learning:
          </strong>
          <br />
          Matrix Rental Solutions believes that artificial intelligence (AI) and
          machine learning can significantly enhance and streamline processes
          required by HUD. Implementing AI-powered algorithms for income
          verification, asset evaluation, and rental history analysis can
          expedite decision-making while ensuring fairness and accuracy.
        </p>
        <p className="mb-5">
          <strong>
            Streamlining Data Connectivity through Payroll Integration:
          </strong>
          <br />
          Matrix Rental Solutions connects to over 1,000,000 employers via our
          payroll connectivity feature, making it easier for renters to provide
          high-fidelity, direct-source data. This seamless integration ensures
          that income and employment verification processes are expedited,
          further reducing administrative burdens for both renters and property
          managers.
        </p>
        <p className="mb-5">
          <strong>Mobile and Tablet Accessibility:</strong>
          <br />
          We understand that the modern world relies heavily on mobile devices
          and tablets for everyday tasks. With 72% of Matrix customers accessing
          rental applications via these devices, our platform is optimized for
          mobile and tablet use. Connecting accounts from these devices
          streamlines the application process, providing a user-friendly and
          efficient experience for applicants.
          <br />
          <br />
          As an industry-leading platform with a primary focus on inclusivity
          and efficiency, we are committed to contributing our expertise and
          technology to further HUD's mission of making housing accessible to
          those who need it most.
          <br />
          <br />
          Our team at Matrix Rental Solutions is excited to collaborate further
          with HUD to drive positive change in the rental housing market. We are
          dedicated to leveraging our transformative platform and expertise to
          contribute to the achievement of HUD's goals and make housing more
          accessible and equitable for everyone.
          <br />
          <br />
          Please feel free to reach out to us for any additional information or
          partnership opportunities. Together, we can pave the way for a
          brighter future in the rental housing sector.
          <br />
          <br />
          Sincerely,
        </p>
        <p>
          Sipho D. Simela
          <br />
          Founder/Chief Executive Officer
          <br />
          Matrix Rental Solutions
          <br />
          <a
            className="underline text-blue-500"
            href="mailto:sipho@mymatrixrent.com"
          >
            sipho@mymatrixrent.com
          </a>
        </p>
      </main>
    </PageWrapper>
  );
};

export const pageQuery = graphql`
  query($slug: Int) {
    article: markdownRemark(
      frontmatter: { slug: { eq: $slug } }
      fields: { category: { eq: "articles" } }
    ) {
      frontmatter {
        title
        date
        url
      }
    }
  }
`;

export default React.memo(NewsArticle04);
